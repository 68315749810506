// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { ApplicationSecurityContext } from './ApplicationSecurityContext';

// import { privateApi, publicApi } from '../../api';

import {
  decrypt, encrypt,
} from '../../utils/crypt';

export type ApplicationSecurityContextProviderProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

// eslint-disable-next-line max-len
export type ApplicationSecurityContextProviderType = React.ComponentType<ApplicationSecurityContextProviderProps>;

export const ApplicationSecurityContextProvider: ApplicationSecurityContextProviderType = (
  props: ApplicationSecurityContextProviderProps,
) => {
  const { children } = props;

  const { Provider } = ApplicationSecurityContext;

  const [encryption, setEncryption] = React.useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRequest = React.useCallback((config) => {
    if (!_.isEmpty(encryption)) {
      /* eslint-disable-next-line no-param-reassign */
      config.headers = {
        ...config.headers,
        'X-Encrypted': true,
      };

      if (config.data) {
        try {
          /* eslint-disable-next-line no-param-reassign */
          config.data = encrypt(config.data, encryption);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.debug(e);
        }
      }
    }

    return config;
  }, [encryption]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleResponse = React.useCallback((config) => {
    if (!_.isEmpty(encryption)) {
      try {
        /* eslint-disable-next-line no-param-reassign */
        config.data = decrypt(config.data, encryption);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.debug(e);
      }
    }

    return config;
  }, [encryption]);

  // React.useEffect(() => {
  //   const requestPrivate = privateApi.interceptors.request.use(handleRequest);
  //   const responsePrivate = privateApi.interceptors.response.use(handleResponse);

  //   return () => {
  //     privateApi.interceptors.request.eject(requestPrivate);
  //     privateApi.interceptors.response.eject(responsePrivate);
  //   };
  // }, [
  //   handleRequest,
  //   handleResponse,
  // ]);

  return React.useMemo(() => (
    <Provider
      value={
              {
                encryption,
                setEncryption,
              }
            }
    >
      {children}
    </Provider>
  ), [
    children,
    encryption,
  ]);
};
