// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

export type ApplicationSecurityContextProps = {
  /** */
  encryption: string;

  /** */
  setEncryption: React.Dispatch<React.SetStateAction<string>>
};

export const ApplicationSecurityContext = React.createContext<ApplicationSecurityContextProps>({
  encryption: '',
  setEncryption: null,
});
