// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Message } from 'semantic-ui-react';

import './ApplicationSecurity.less';

import { ApplicationSecurityContextProvider } from './ApplicationSecurityContextProvider';

import { getApplicationClassName } from '../utils/className';

const className = getApplicationClassName('security');

export type ApplicationSecurityProps = {
  /** */
  children: React.ReactNode;
};

export type ApplicationSecurityType = React.ComponentType<ApplicationSecurityProps>;

const isValidHost = /localhost/.test(window.location.host) || /.*\.jvs-mairistem\..*/.test(window.location.host);

export const ApplicationSecurity: ApplicationSecurityType = (
  props: ApplicationSecurityProps,
) => {
  const {
    children,
  } = props;

  return (
    <ApplicationSecurityContextProvider>
      {isValidHost && children}
      {!isValidHost && (
        <Message
          className={className}
          icon="user secret"
          header="Votre connexion n'est pas sécurisé"
          content="Le site sur lequel vous voulez acceder n'est pas hebergé par Mairistem."
          floating
          error
        />
      )}
    </ApplicationSecurityContextProvider>
  );
};
