// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { ApplicationEnvironmentContext } from './ApplicationEnvironmentContext';

export type ApplicationEnvironmentContextProviderProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

// eslint-disable-next-line max-len
export type ApplicationEnvironmentContextProviderType = React.ComponentType<ApplicationEnvironmentContextProviderProps>;

export const ApplicationEnvironmentContextProvider: ApplicationEnvironmentContextProviderType = (
  props: ApplicationEnvironmentContextProviderProps,
) => {
  const { children } = props;

  const { Provider } = ApplicationEnvironmentContext;

  const [mode, setMode] = React.useState<'development' | 'testing' | 'staging' | 'production'>('development');

  React.useEffect(() => {
    if (/.*\.jvs-mairistem\.local$/.test(window.location.host)) {
      setMode('development');
    } else if (/.*\.jvs-mairistem\.dev$/.test(window.location.host)) {
      setMode('development');
      if (/^.*\.qa\./.test(window.location.host)) {
        setMode('testing');
      }
      if (/^.*\.pp\./.test(window.location.host)) {
        setMode('staging');
      }
    } else if (/.*\.jvs-mairistem\.fr$/.test(window.location.host)) {
      setMode('production');
    }
  }, []);

  return React.useMemo(() => (
    <Provider value={mode}>
      {children}
    </Provider>
  ), [
    children,
    mode,
  ]);
};
