// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';
import { toast } from 'react-toastify';

import { ApplicationSessionContext } from './ApplicationSessionContext';

import { UserService } from '../../entities/User';

import { privateApi, publicApi } from '../../api';

import { ApplicationSecurityContext } from '../ApplicationSecurity';

export type ApplicationSessionContextProviderProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

// eslint-disable-next-line max-len
export type ApplicationSessionContextProviderType = React.ComponentType<ApplicationSessionContextProviderProps>;

export const ApplicationSessionContextProvider: ApplicationSessionContextProviderType = (
  props: ApplicationSessionContextProviderProps,
) => {
  const { children } = props;

  const { Provider } = ApplicationSessionContext;

  const { setEncryption } = React.useContext(ApplicationSecurityContext);

  const [user] = UserService.queryUser();

  // const handleRefresh = React.useCallback(
  //   _.debounce(
  //     (token) => {
  //       if (token) {
  //         UserService.refresh(token);
  //       }
  //     },
  //     30 * 60 * 1000,
  //     { leading: true, trailing: true },
  //   ),
  //   [],
  // );

  // React.useEffect(() => {
  //   const listener = () => handleRefresh(user);

  //   window.addEventListener('focus', listener);
  //   window.addEventListener('online', listener);

  //   return () => {
  //     window.removeEventListener('focus', listener);
  //     window.removeEventListener('online', listener);
  //   };
  // }, [
  //   user,
  //   handleRefresh,
  // ]);

  React.useEffect(() => {
    // const requestId = privateApi.interceptors.request.use((config) => {
    //   if (user) {
    //     console.log(config.headers)
    //     config.headers.set('Authorization', `Bearer ${user.token.access}`)
    //     config.headers.set('X-Utilisateur', user.identifiant)

    //     config.headers.set('X-Tenant', user.tenant?.identifiant)
    //     config.headers.set('X-Component', user.component?.identifiant)
    //   }

    //   return config;
    // });

    const responseId = privateApi.interceptors.response.use(
      (value) => value,
      (error) => {
        if (user) {
          if ([401, 498].includes(error.response?.status)) {
            return UserService.refresh(user)
              .then((bearer) => {
                if (bearer) {
                  return publicApi.get({
                    ...error.config,
                    headers: {
                      ...error.config.headers,
                      Authorization: `Bearer ${bearer}`,
                    },
                  });
                }

                toast.info('Votre session a expiré', { toastId: 'INF_SESSION_EXPIRED' });

                // TODO: voir pourquoi le reject ne declenche pas le catch
                UserService.logout(user);

                return Promise.reject(new Error('Invalid token'));
              })
              .catch(() => {
                toast.info('Votre session a expiré', { toastId: 'INF_SESSION_EXPIRED' });

                return UserService.logout(user);
              });
          }
          if ([449].includes(error.response?.status)) {
            toast.info('Votre session a expiré', { toastId: 'INF_SESSION_EXPIRED' });

            return UserService.logout(user);
          }
        }

        return Promise.reject(error);
      },
    );

    return () => {
      // privateApi.interceptors.request.eject(requestId);
      privateApi.interceptors.response.eject(responseId);
    };
  }, [user]);

  React.useEffect(() => {
    React.startTransition(() => {
      setEncryption(user?.cle);
    });
  }, [
    user,
  ]);

  return React.useMemo(() => (
    <Provider
      value={
              {
                user,
              }
            }
    >
      {children}
    </Provider>
  ), [
    user,
    children,
  ]);
};
